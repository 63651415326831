import React, { useState, useEffect } from "react"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { CSSRulePlugin } from "gsap/CSSRulePlugin"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Title from "../components/title"
import Storybook from "../components/storybook"
import MiniBlogPost from "../components/blogPostMini"

const Index = ({ data, location }) => {
    const posts = data.allMdx.edges;
    const sortedPosts = posts.sort((a,b) => (Date.parse(a.node.frontmatter.date) < Date.parse(b.node.frontmatter.date)) ? 1 : ((Date.parse(b.node.frontmatter.date) < Date.parse(a.node.frontmatter.date)) ? -1 : 0));

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        gsap.registerPlugin(CSSRulePlugin);

        var tl = gsap.timeline();

        tl.add(gsap.fromTo("nav, #taj-img", { opacity: 0}, {duration: 1, opacity: 1}));
        tl.add(gsap.fromTo("#splash-static-title", {x: -150, opacity: 0}, {duration: 0.3, x: 0, opacity: 1}));
        tl.add(gsap.fromTo("#splash-dynamic-title", {x: -150, opacity: 0}, {duration: 0.3, x: 0, opacity: 1}));
        
        gsap.timeline({
            scrollTrigger: {
                trigger: "#taj-splash",
                start: "top top",
                end: "bottom top",
                scrub: true
            }
        }).fromTo("#taj-splash-img", {y: 0}, {y: 300}, 0);
        
        // Array.from(document.querySelectorAll('.blog-mini-wrapper')).forEach(function(element) {
        //     element.addEventListener('mouseover', event => {
        //         var title = element.querySelector('.mini-post-title');
        //         var height = title.offsetHeight;

        //         title.style.bottom = (height + 55) + 'px';
        //     });

        //     element.addEventListener('mouseout', event => {
        //         var title = element.querySelector('.mini-post-title');
        //         var height = title.offsetHeight;

        //         title.style.bottom = (height + 33) + 'px';
        //     });
        // });

        const script = document.createElement('script');

        script.src = "https://platform.twitter.com/widgets.js";
        script.async = true;
    
        document.body.appendChild(script);
    
        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
      <Layout location={location}>
        <SEO
          title="Home"
          keywords={[`blog`, `law`, `barristers`, `tajwar`]}
        />

        <div className="row" id="taj-splash">
        <div id="offset-column" className="col-md-2">

        </div>
            <div className="col-md-5 col-sm-12" id="splash-title-container">
                <div className="splash-overlay"></div>
                <div id="splash-title">
                    <h2>Hey there<span className="color-primary">.</span></h2>
                    <h1 id="splash-dynamic-title">I'm Taj</h1>
                    <div id="hero-subtitle" className="mt-3 mb-3 text-paragraph">
                        Called to the Bar of England and Wales, with a focus on human rights and community justice. 
                        I work collaboratively with organisations and individuals to pursue meaningful change through advocacy, consultancy and project management. 
                        My mission is to bring people and ideas together to achieve worthwhile outcomes for us all. To build movements of lasting change.
                    </div>
                    <a className="body-link mt-4" href="#about">Scroll for more</a>
                </div>
            </div>

            <div id="taj-splash-img">
                <img src={'../../taj-about.png'} alt="hello" id="taj-img" />
            </div>
        </div>

        <div className="row mt-5" id="about">
            <div className="col-12 col-md-5 text-xl-right text-center">
                <img className="img-responsive" src={'../../taj-stare.png'} />
            </div>

            <div id="about-text" className="col-12 col-md-6 mb-5">
                <div className="mb-5 mt-5">
                    <Title>My Journey</Title>
                    <p className="text-paragraph">
                        I studied Law and Politics at Essex University and passed the Bar at BPP in London. My career has taken me to numerous charities and social 
                        change organisations, with people from Bangladesh to Venezuela, and from the Local Magistrates all the way up to the International Criminal Court. 
                        I currently work with Migrant Voice, leading campaigns of change across the UK. 
                    </p>

                    <a className="body-link" href="/about">Find out more about me &nbsp; <i class="fas fa-external-link-alt color-primary"></i></a>
                </div>

                <div className="mt-5">
                    <Title>My Skillset</Title>
                    <p className="text-paragraph">
                        I focus on research, consultancy, campaigning and legal drafting to deliver systems change and transitional justice, 
                        with a record of over 30 legal submissions to various Bodies, representing over 50 clients and managing over 20 projects with various Councils, 
                        Charities and the National Health Service. I also support campaigns nationally on behalf of refugees and migrants and develop strategies of change with 
                        stakeholders.
                    </p>

                    <a className="body-link" href="/blog">Read about my work &nbsp; <i class="fas fa-external-link-alt color-primary"></i></a>
                </div>

                <div className="mt-5">
                    <Title>My Process</Title>
                    <p className="text-paragraph">
                        I utilise a range of project management tools with a focused application of in-depth research, discovery and synthesis to work through complex issues. 
                        I ensure a creative and inclusive engagement and approach, shaped through the lens of achieving meaningful and equitable outcomes <strong><u>with</u></strong> those most 
                        affected at the heart of my work.
                    </p>
                </div>
            </div>

            <div id="mini-blogs-section" className="col-12 text-center d-flex justify-content-center flex-column align-items-center">
                <div className="d-flex align-self-start">
                    <h2>MY RECENT RAMBLINGS<span className="color-primary">.</span></h2>
                </div>
                
                <div className="col-12 d-flex justify-content-center flex-row flex-wrap">
                    {
                        sortedPosts.slice(0, 4).map(({ node }) => {
                            const title = node.frontmatter.title || node.fields.slug
                            const imgTitle = `${node.frontmatter.category}.jpg`
            
                            return (
                                <MiniBlogPost slug={node.fields.slug} title={title} img={`../../${imgTitle}`} category={node.frontmatter.category} description={node.frontmatter.description}>{title}</MiniBlogPost>
                            )
                        })
                    }
                </div>

                <div className="mt-5">
                    <a className="fancy-button" href="/blog">SEE MORE</a>
                </div>
            </div>

            <div className="container contact-container">
                <div className="row mt-4">
                    <div className="col-12 text-center">
                        <Title>COLLABORATE WITH ME</Title>
                    </div>

                    <div className="col-12 col-md-4">
                        <a href="https://twitter.com/TajwarShelim">
                            <i className="fab fa-twitter"></i>
                            <h3>Tweet At Me<span className="color-primary">.</span></h3>
                        </a>
                    </div>

                    <div className="col-12 col-md-4">
                        <a href="https://www.instagram.com/tajwarshelim/">
                            <i className="fab fa-instagram"></i>
                            <h3>Slide Into My DMs<span className="color-primary">.</span></h3>
                        </a>
                    </div>

                    <div className="col-12 col-md-4">
                        <a href="https://www.facebook.com/tajwar.shelim">
                        <i className="fab fa-facebook"></i>
                        <h3>Find Me On Facebook<span className="color-primary">.</span></h3></a>
                    </div>

                    <div className="col-12 col-md-4">
                        <a href="https://www.linkedin.com/in/tajwar-shelim/">
                            <i className="fab fa-linkedin"></i>
                            <h3>Lets Link Up<span className="color-primary">.</span></h3>
                        </a>
                    </div>

                    <div className="col-12 col-md-4">
                        <a href="mailto:tajwar@collaboratecic.com">
                            <i className="fas fa-envelope-open-text"></i>
                            <h3>Invade My Inbox<span className="color-primary">.</span></h3>
                        </a>
                    </div>
                </div>
            </div>

            {/* <div className="d-none d-md-block col-12 col-lg-4 text-center mt-5">
                <Title>TWITTER FEED</Title>
                <a className="twitter-timeline" data-width="600" data-height="1000" href="https://twitter.com/TajwarShelim?ref_src=twsrc%5Etfw">Tweets by TajwarShelim</a>
                </div> */}
        </div>
      </Layout>
    )
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    localSearchBlog {
      index
      store
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
            category
          }
        }
      }
    }
  }
`
